import * as React from "react"
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import {
  Container,
  Flex,
  Box,
  Space,
  Heading,
  Text,
  Avatar,
} from "../components/ui"
import { avatar as avatarStyle } from "../components/ui.css"
import * as styles from "./blog-post.css"

export default function AppPrivacy({pageContext}) {

  return (
    <Layout {...pageContext} description={pageContext.summary}>
      <Container> 
        <Box paddingY={5}>
          <Heading as="h1" >
            Privacy Policy for {pageContext.title}
          </Heading>
         
        
          <Space size={4} />
      
          {pageContext.privacyPolicy && (
           <ReactMarkdown children={pageContext.privacyPolicy.privacyPolicy} remarkPlugins={[remarkGfm]} />
           )}
          
        </Box>
      </Container>
    </Layout>
  )
}
